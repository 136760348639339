import { useQuery } from "@tanstack/react-query";
import { getPerformanceDataByMachineIdOwn } from "api/machine.service";

export const useMachinePerformanceDataQuery = (MachineId) => {
  return useQuery({
    queryKey: ["machine", MachineId, "performance"],
    queryFn: () => getPerformanceDataByMachineIdOwn(MachineId),
    // enabled: !!serialNumber,
  });
};

import { useMutation } from "@tanstack/react-query";
// import { useSelector } from "react-redux";
import { updateMachine } from "../../api/machine.service";

export const useUpdateMachineSoftware = () => {
  // const myAccount = useSelector((state) => state.user.user);

  // const isAdmin =
  //   myAccount &&
  //   (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      return updateMachine(data?.MachineId, {});
      // if (isAdmin) return changeMachineRoom(data?.MachineId, data?.RoomId);
      // else return changeOwnMachineRoom(data?.MachineId, data?.RoomId);
    },
  });
};

"use client";

import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import videoCreateFactory from "../../shared/utils/videos/create_factory.mp4";
import videoAddUser from "../../shared/utils/videos/add_user.mp4";
import videoManagingRooms from "../../shared/utils/videos/managing_rooms.mp4";
import videoCRUDRooms from "../../shared/utils/videos/CRUD_rooms.mp4";
import videoAddMachine from "../../shared/utils/videos/add_machine.mp4";

export default function TutorialVideoDialog({
  open,
  setOpen,
  video,
  selectedTutorial,
}) {
  const selectedVideo = getVideo(selectedTutorial?.video);
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full  ${selectedVideo == null ? "sm:max-w-lg" : "sm:max-w-7xl"} sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold text-gray-900"
                >
                  {selectedTutorial?.title}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {selectedTutorial?.description}
                  </p>
                </div>
                {selectedVideo !== null ? (
                  <video class="mt-8 h-full w-full rounded-lg" controls>
                    <source src={selectedVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <div className="mt-8 flex items-center ">
                    <ExclamationTriangleIcon className="h-8 w-8 text-yellow-500" />
                    <p className="ml-2 text-sm text-gray-500">
                      Video not available at the moment :( <br />
                      Please try again later.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Close
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

// import { useState } from "react";
// import {
//   Dialog,
//   DialogBackdrop,
//   DialogPanel,
//   DialogTitle,
// } from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/24/outline";
// import videoCreateFactory from "../../shared/utils/videos/create_factory.mp4";
// import videoAddUser from "../../shared/utils/videos/add_user.mp4";
// import videoManagingRooms from "../../shared/utils/videos/managing_rooms.mp4";

// export default function TutorialVideoDialog({
//   open,
//   setOpen,
//   video,
//   selectedTutorial,
// }) {
//   const selectedVideo = getVideo(selectedTutorial?.video);
//   return (
//     <Dialog open={open} onClose={setOpen} className="relative z-10">
//       <DialogBackdrop
//         transition
//         className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
//       />

//       <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
//         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//           <DialogPanel
//             transition
//             className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-7xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
//           >
//             <div>
//               <div className="mt-3 text-center sm:mt-5">
//                 <DialogTitle
//                   as="h3"
//                   className="text-base font-semibold text-gray-900"
//                 >
//                   {selectedTutorial?.title}
//                 </DialogTitle>
//                 <div className="mt-2">
//                   <p className="text-sm text-gray-500">
//                     {selectedTutorial?.description}
//                   </p>
//                 </div>
//                 <video class="h-full w-full rounded-lg mt-2" controls>
//                   <source src={selectedVideo} type="video/mp4" />
//                   Your browser does not support the video tag.
//                 </video>
//               </div>
//             </div>
//             <div className="mt-5 sm:mt-6">
//               <button
//                 type="button"
//                 onClick={() => setOpen(false)}
//                 className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
//               >
//                 Go back to home
//               </button>
//             </div>
//           </DialogPanel>
//         </div>
//       </div>
//     </Dialog>
//   );
// }

const getVideo = (video) => {
  switch (video) {
    case "create-factory":
      return videoCreateFactory;
    case "add-user":
      return videoAddUser;
    case "managing-rooms":
      return videoManagingRooms;
    case "manage-rooms":
      return videoCRUDRooms;
    case "add-machine":
      return videoAddMachine;
    default:
      return null;
  }
};

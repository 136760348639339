import React from "react";
import { useOutletContext } from "react-router-dom";
import EditFactorySlidePanel from "../edit/EditFactorySlidePanel";
import DeleteFactoryDialog from "../Components/DeleteFactoryDialog";
import { useSelector } from "react-redux";
import { useMachinesByFactoryIdQuery } from "../../../hooks/machine/useMachinesByFactoryIdQuery";

export default function ViewFactoryDetails() {
  const myAccount = useSelector((state) => state.user.user);
  const { factoryDetails } = useOutletContext();
  const [editFactoryOpen, setEditFactoryOpen] = React.useState(false);
  const [deleteFactoryOpen, setDeleteFactoryOpen] = React.useState(false);

  let factoryRole = myAccount?.Factories.find(
    (factory) => factory.id === factoryDetails?.id,
  )?.FactoryUser?.factoryRole;

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");
  const { data, isLoading, error } = useMachinesByFactoryIdQuery(
    factoryDetails?.id,
  );

  const machines = data?.data?.machines;
  console.log("datdatadataa", data);
  return (
    <>
      <EditFactorySlidePanel
        createFactoryOpen={editFactoryOpen}
        setCreateFactoryOpen={setEditFactoryOpen}
        currentFactoryDetails={factoryDetails}
      />
      <DeleteFactoryDialog
        factoryId={factoryDetails?.id}
        factoryName={factoryDetails?.name}
        open={deleteFactoryOpen}
        setOpen={setDeleteFactoryOpen}
      />
      <div className="justify-between gap-x-4 sm:grid sm:grid-cols-2">
        <div className="w-full ">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Factory Information
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Factory name
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.name}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    VAT number
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.identifier}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Address</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.address}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">City</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.city}
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Postal code
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.zip}
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Country</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.country}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="my-4 bg-white shadow-sm ring-1  ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Edit Factory
              </h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    Edit the factory details, such as the name, address, city,
                    postal code, country, and VAT number.
                  </p>
                </div>
                <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <button
                    type="button"
                    onClick={() => setEditFactoryOpen(true)}
                    className="inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400 disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={!isAdmin && factoryRole !== "Manager"}
                  >
                    {/* Edit Details */}
                    Modify Details
                    {/* Make Changes */}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 bg-white shadow-sm ring-1  ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Remove
              </h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    Removing the factory will remove all the data associated
                    with it, including the users, machines, and the factory
                    itself. This action is irreversible.
                  </p>
                </div>
                <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={() => setDeleteFactoryOpen(true)}
                    disabled={!isAdmin && factoryRole !== "Manager"}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full sm:mt-0">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Status
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Machine status
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {factoryDetails?.machinesStatus == null
                      ? "N/A"
                      : factoryDetails?.machinesStatus}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    No. of machines
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {machines?.length || 0}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useState, useEffect } from "react";
import FactoryRow from "./FactoryRow";
import TablePagination from "./TablePagination";

export default function FactoriesTable({
  data,
  isLoading,
  isFetching,
  isPlaceholderData,
  page,
  setPage,
  pageSize,
  setPageSize,
  totalRows,
}) {
  useEffect(() => {
    // Ensure tooltips are reinitialized after data updates
    if (window.HSTooltip) {
      window.HSTooltip.autoInit(); // Initializes the tooltips
    }
  }, [data]);
  return (
    <div className="px-0">
      <div className=" flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1">
                    Status
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Factory Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Address
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    City
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Country
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white relative">
                {isLoading ? (
                  <tr className="border-t border-gray-200">
                    <td colSpan={5}>
                      <div className="flex justify-center">
                        <div className="my-4 animate-spin rounded-full w-8 h-8 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-500 border-t-transparent"></div>
                      </div>
                    </td>
                  </tr>
                ) : data?.length > 0 ? (
                  <>
                    {isFetching && isPlaceholderData ? (
                      <div className="absolute inset-0 bg-gray-300 opacity-50 flex items-center justify-center">
                        <div className="animate-spin rounded-full w-8 h-8 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-500 border-t-transparent"></div>
                      </div>
                    ) : null}
                    {data.map((factory, factoryIdx) => (
                      <FactoryRow key={factory.id} factory={factory} factoryIdx={factoryIdx} />
                    ))}
                  </>
                ) : (
                  <tr className="border-t border-gray-200">
                    <td colSpan={5} className=" py-2 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3">
                      No factories found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <TablePagination
              page={page}
              setPage={setPage}
              totalRows={totalRows}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

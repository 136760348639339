import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { MdCastConnected, MdOutlineCast } from "react-icons/md";
import { LuFactory } from "react-icons/lu";
import { LuHome } from "react-icons/lu";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userService } from "shared/services/authentication.service";
import { useMyAccountQuery } from "hooks/user";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import logo2 from "shared/utils/images/logo2.png";
import NotificationBellDropdown from "./NotificationBellDropdown";
import React from "react";
import { FiHelpCircle } from "react-icons/fi";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const userNavigation = [
  // { name: "Your Profile", href: "/profile" },
  { name: "Settings", href: "/settings/general" },
  { name: "Help Center", href: "/help-center" },
  { name: "Sign out", href: "#", onClick: () => userService.logout() },
];

const authorizedNavigation = [
  // { name: "Home", href: "/home", icon: HomeIcon },
  { name: "Commander", href: "/commander", icon: LuFactory },
  { name: "Navigator", href: "/navigator/connect", icon: MdOutlineCast },
];

const unauthorizedNavigation = [
  // { name: "Home", href: "/home", icon: HomeIcon },
  { name: "Commander", href: "/commander", icon: LuFactory },
  // { name: "Navigator", href: "/navigator/connect", icon: MdOutlineCast },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderNavBrand() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading } = useMyAccountQuery({
    staleTime: 1000 * 60 * 60 * 6,
  });
  let myAccount = data?.data.user;
  console.log("myAccount", myAccount);

  const isAdminOrTechnician =
    myAccount &&
    (myAccount.Role.name === "Admin" ||
      myAccount.Role.name === "Super Admin" ||
      myAccount.Role.name === "Technician");

  const navigation = (
    isAdminOrTechnician ? authorizedNavigation : unauthorizedNavigation
  ).map((item) => ({
    ...item,
    current: location.pathname.includes(item.href),
  }));

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="bg-gradient-to-r from-nilfisk to-[#38afd9]"
        >
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img alt="Your Company" src={logo2} className="h-8 w-auto" />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-sky-500 font-bold text-white"
                            : "text-white hover:bg-sky-600 hover:bg-opacity-85",
                          "rounded-md px-3 py-2 text-sm font-bold",
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <button onClick={()=> navigate('/help-center')} className="relative rounded-full   p-1 text-blue-200 hover:text-white focus:outline-none">
                    <FiHelpCircle className="h-6 w-6 text-blue-200 " />
                  </button>
                  <NotificationBellDropdown />
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <MenuButton className="relative flex max-w-xs items-center rounded-full  text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-flex size-[32px] items-center justify-center rounded-full bg-blue-100 text-sm font-semibold leading-none text-blue-800 ">
                          {isLoading
                            ? "NA"
                            : myAccount?.firstName.charAt(0) +
                              myAccount?.lastName.charAt(0)}
                        </span>
                        {isLoading ? (
                          <div className="ml-4 h-3 w-[130px] animate-pulse rounded bg-slate-200"></div>
                        ) : (
                          <span className="hidden lg:flex lg:items-center">
                            <span
                              className="ml-4 text-sm font-semibold leading-6 text-white"
                              aria-hidden="true"
                            >
                              {myAccount?.firstName} {myAccount?.lastName}
                            </span>
                            <ChevronDownIcon
                              className="ml-2 mr-2 h-5 w-5 text-gray-200"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          <a
                            href={item.href}
                            onClick={(event) => {
                              event.preventDefault();
                              if (item.name === "Sign out") {
                                item.onClick();
                              }
                              navigate(item.href);
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                          >
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-blue-500 p-2 text-blue-200 hover:bg-blue-400 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <XMarkIcon
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  href={item.href}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-blue-100 text-white"
                      : "text-white hover:bg-blue-400 hover:bg-opacity-75",
                    "block rounded-md px-3 py-2 text-base font-medium",
                  )}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
            <div className="border-t border-blue-600 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-sm font-semibold leading-none text-blue-800 ">
                    {isLoading
                      ? "NA"
                      : myAccount?.firstName.charAt(0) +
                        myAccount?.lastName.charAt(0)}
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {myAccount?.firstName} {myAccount?.lastName}
                  </div>
                  <div className="text-sm font-medium text-blue-200">
                    {myAccount?.email}
                  </div>
                </div>
                <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-blue-500 p-1 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-blue-400 hover:bg-opacity-75"
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        {/* <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {console.log("location.pathname", location)}
              {getPageName(location.pathname)}
            </h1>
          </div>
        </header> */}
        <main>
          <div className="mx-auto max-w-full px-4 py-6 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}

function getPageName(pathname) {
  const firstSegment = pathname.split("/")[1]; // Gets the first segment after the base URL
  return firstSegment.charAt(0).toUpperCase() + firstSegment.slice(1); // Capitalizes the first letter
}

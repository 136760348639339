import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useMachineSWVersionQuery } from "hooks/machine/useMachineSWVersionQuery";
import RemoveMachineDialog from "./components/RemoveMachineDialog";
import { useSelector } from "react-redux";
import {
  PhotoIcon,
  UserCircleIcon,
  DocumentIcon,
  DocumentArrowDownIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import {
  downloadWashProgramFile,
  uploadWashProgramFile,
} from "../../api/foamatic.service";
import { enqueueSnackbar } from "notistack";
import UploadedFoamaticDialog from "./components/UploadedFoamaticDialog";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import ErrorUploadedFoamaticDialog from "./components/ErrorUploadedFoamaticDialog";

export default function ViewMachineFoamatic() {
  const myAccount = useSelector((state) => state.user.user);
  const [isDragging, setIsDragging] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileSize, setFileSize] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorUploadMessage, setErrorUploadMessage] = useState("");
  const [openErrorUploadedDialog, setOpenErrorUploadedDialog] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  const [removeMachineOpen, setRemoveMachineOpen] = React.useState(false);
  const [openSuccessfullyUploadedDialog, setOpenSuccessfullyUploadedDialog] =
    useState(false);
  const { machineData } = useOutletContext();

  let canUpdate =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  let factoryRole = myAccount?.Factories.find(
    (factory) => factory.id === machineData?.FactoryId,
  )?.FactoryUser?.factoryRole;

  if (factoryRole === "Technician" || factoryRole === "Manager") {
    canUpdate = true;
  }
  const handleUploadToMachine = async () => {
    setIsLoadingUpload(true);
    if (!fileToUpload) {
      console.error("No file to upload.");
      return;
    }

    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          // Parse the JSON data from the file
          const json = JSON.parse(e.target.result);
          console.log("Uploaded JSON:", json);

          // Check if the JSON structure is valid
          if (json.FoamaticType != null) {
            // Send the JSON data to the backend
            const response = await uploadWashProgramFile(
              machineData?.serialNumber,
              json,
            );
            console.log("File uploaded successfully", response);
            if (response?.data?.result?.payload?.Response === "FAIL") {
              if (response?.data?.result?.payload?.ErrorCode === 128) {
                setOpenErrorUploadedDialog(true);
                setErrorUploadMessage(
                  "The Wash Program is corrupted using old internal Wash Program",
                );
              } else if (
                response?.data?.result?.payload?.ErrorCode === 2048
              ) {
                setOpenErrorUploadedDialog(true);
                setErrorUploadMessage(
                  " Wrong Foamatic type. The Wash Program Foamtic Type is not same as the Machine",
                );
              
              }
            } else {
              setOpenSuccessfullyUploadedDialog(true);
            }
            // enqueueSnackbar(
            //   <div>
            //     <p className="text-sm font-medium text-gray-900">
            //       Wash program uploaded!
            //     </p>
            //     <p className="mt-1 text-sm text-gray-500">
            //       You can now restart the machine to apply the changes.
            //     </p>
            //   </div>,
            //   { variant: "success" },
            // );
            // Reset the file and upload progress
            setFileToUpload(null);
            setUploadProgress(100);
            setIsLoadingUpload(false);
          } else {
            console.error("Invalid JSON structure: Missing FoamaticType");
            setErrorUploadMessage(true);
            enqueueSnackbar(
              <div>
                <p className="text-sm font-medium text-gray-900">
                  There was an error uploading the wash program.
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  Please make sure the file is a valid wash program.
                </p>
              </div>,
              { variant: "error" },
            );
            setIsLoadingUpload(false);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          enqueueSnackbar(
            <div>
              <p className="text-sm font-medium text-gray-900">
                There was an error uploading the wash program.
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Please make sure the file is a valid wash program and the
                machine is connected.
              </p>
            </div>,
            { variant: "error" },
          );
          setIsLoadingUpload(false);
        }
      };

      // Start reading the file
      reader.readAsText(fileToUpload);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsLoadingUpload(false);
    }
  };
  const handleDownloadWP = async () => {
    setIsLoadingDownload(true);
    try {
      // Fetch the file data
      // machineData?.serialNumber
      const data = await downloadWashProgramFile(machineData?.serialNumber);
      console.log("data", data);

      // Convert the data to a JSON string if it's an object
      const jsonData = JSON.stringify(data.data.washProgram);

      // Create a URL for the file data
      const url = window.URL.createObjectURL(
        new Blob([jsonData], { type: "application/json" }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `W${machineData?.serialNumber}.JSO`); // or the appropriate file extension

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      setIsLoadingDownload(false);
      link.parentNode.removeChild(link);
    } catch (error) {
      setIsLoadingDownload(false);
      enqueueSnackbar(
        <div>
          <p className="text-sm font-medium text-gray-900">
            There was an error downloading the wash program.
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Make sure the machine is connected and try again. If the problem
            persists, contact support.
          </p>
        </div>,
        { variant: "error" },
      );
      console.error("Error downloading file:", error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && !file.name.match(/\.(json|jso|JSO|JSON)$/i)) {
      setErrorMessage("Invalid file type. Please upload a .json or .jso file.");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const json = JSON.parse(e.target.result);
          console.log("Uploaded JSON:", json);

          if (json.FoamaticType != null) {
            setFileToUpload(file);
            setErrorMessage(""); // Clear any previous error
          } else {
            setErrorMessage(
              "Invalid file. This file doesn't seem to be a valid wash program.",
            );
          }
          // Handle the JSON data as needed
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);

      // Simulate upload progress
      let progress = 0;
      const interval = setInterval(() => {
        progress += 1;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 5);
    }
  };
  const handleUploadWP = async (event) => {
    const file = event.target.files[0];
    if (file && !file.name.match(/\.(json|jso|JSO|JSON)$/i)) {
      setErrorMessage("Invalid file type. Please upload a .json or .jso file.");
      return;
    }

    if (file) {
      const reader = new FileReader();
      // console.log("file", reader)
      reader.onload = async (e) => {
        try {
          const json = JSON.parse(e.target.result);
          console.log("Uploaded JSON:", json);
          if (json.FoamaticType != null) {
            setFileToUpload(file);
            setErrorMessage(""); // Clear any previous error
          } else {
            setErrorMessage(
              "Invalid file. This file doesn't seem to be a valid wash program.",
            );
          }
          // Handle the JSON data as needed
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);

      // Simulate upload progress
      let progress = 0;
      const interval = setInterval(() => {
        progress += 1;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 5);
    }
    event.target.value = "";
  };
  const handleRemoveFile = () => {
    setFileToUpload(null);
  };

  return (
    <>
      <UploadedFoamaticDialog
        open={openSuccessfullyUploadedDialog}
        setOpen={setOpenSuccessfullyUploadedDialog}
      />
      <ErrorUploadedFoamaticDialog
        open={openErrorUploadedDialog}
        setOpen={setOpenErrorUploadedDialog}
        message={errorUploadMessage}
      />
      <div className="mx-auto max-w-2xl justify-between gap-x-4 sm:grid sm:grid-cols-1">
        <div className="w-full ">
          <div className=" bg-white shadow-sm ring-1  ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Upload Wash Program
              </h3>
              {/* <div className="max-w-xl text-sm text-gray-500 mt-2">
                  <p>Upload a wash program to the machine.</p>
                </div> */}
              <dl className="mt-4 divide-y divide-gray-100">
                <div className="col-span-full">
                  {fileToUpload ? (
                    <>
                      <div className="mt-4 rounded-lg border border-gray-200 bg-gray-50 p-4">
                        <div className="flex items-center justify-between ">
                          <div className="flex items-center  ">
                            <DocumentIcon className="h-10 w-10 text-gray-400" />
                            <div className="ml-4 ">
                              <p
                                className="max-w-xs  text-sm font-medium text-gray-900 sm:max-w-sm md:max-w-md lg:max-w-lg"
                                title={fileToUpload.name} // Shows full name on hover
                              >
                                {fileToUpload.name}
                              </p>
                              <p className="text-xs text-gray-500">
                                {(fileToUpload.size / 1024).toFixed(2)} KB
                              </p>
                            </div>
                          </div>
                          <button
                            className="text-gray-500 hover:text-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
                            onClick={() => handleRemoveFile()}
                            disabled={isLoadingUpload}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>

                        {/* Progress bar aligned at the bottom */}
                        <div className="mt-4 flex items-center space-x-4">
                          <div className="relative h-2 w-full rounded-full bg-gray-200">
                            <div
                              className={`absolute top-0 h-full rounded-full ${
                                uploadProgress < 100
                                  ? "bg-blue-500"
                                  : "bg-green-500"
                              }`}
                              style={{ width: `${uploadProgress}%` }}
                            ></div>
                          </div>
                          <span className="text-sm font-medium text-gray-700">
                            {uploadProgress}%
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 flex text-sm">
                        <div
                          href="#"
                          className="group inline-flex items-center text-gray-500 "
                        >
                          <QuestionMarkCircleIcon
                            className="h-5 w-5 text-gray-400 "
                            aria-hidden="true"
                          />
                          <span className="ml-2">
                            The upload process may take a few minutes.
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end  pt-2 text-end">
                        <div className="flex items-center space-x-4">
                          {isLoadingUpload && (
                            <div className="h-8 w-8 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-500 border-t-transparent"></div>
                          )}
                          <button
                            type="button"
                            onClick={() => handleUploadToMachine()}
                            className="inline-flex w-24 items-center justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                            disabled={
                              isLoadingUpload || isLoadingDownload || !canUpdate
                            }
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${
                          isDragging ? "border-blue-400 bg-blue-50" : ""
                        }`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        <div className="text-center">
                          {isDragging ? (
                            <>
                              <DocumentArrowDownIcon
                                aria-hidden="true"
                                className="mx-auto h-12 w-12 text-gray-300"
                              />
                              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <p className="pl-1">Drop file here</p>
                              </div>
                              <p className="text-xs leading-5 text-gray-600">
                                .JSO, .JSON up to 10MB
                              </p>
                            </>
                          ) : (
                            <>
                              <DocumentIcon
                                aria-hidden="true"
                                className="mx-auto h-12 w-12 text-gray-300"
                              />
                              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleUploadWP}
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs leading-5 text-gray-600">
                                .JSO, .JSON up to 10MB
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                      <p className="mb-6 mt-2 text-sm text-red-500">
                        {errorMessage}
                      </p>
                    </>
                  )}
                </div>
              </dl>
            </div>
          </div>
          <div className="my-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Download Wash Program
              </h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    Download the current wash program from the machine. This
                    process may take a few minutes.
                  </p>
                </div>
                <div className="mx-0 mt-5 text-end sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <div className="flex w-full items-center justify-end space-x-4 sm:w-auto">
                    {isLoadingDownload && (
                      <div className="h-8 w-8 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-500 border-t-transparent"></div>
                    )}
                    <button
                      type="button"
                      onClick={() => handleDownloadWP()}
                      className="inline-flex w-24 items-center justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                      disabled={
                        isLoadingUpload || isLoadingDownload || !canUpdate
                      }
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import {
  Bars4Icon,
  CalendarIcon,
  ClockIcon,
  PhotoIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { PiFactoryLight } from "react-icons/pi";
import { IoPeopleOutline } from "react-icons/io5";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { MdOutlineHeatPump } from "react-icons/md";
import { MdCastConnected, MdOutlineCast } from "react-icons/md";
import { BiTransferAlt } from "react-icons/bi";
import CreateFactoryVideo from "../../components/Tutorials/TutorialVideoDialog";
import { useState } from "react";
import TutorialVideoDialog from "../../components/Tutorials/TutorialVideoDialog";
const items = [
  {
    title: "How to create a Factory",
    description:
      "Video Tutorial: Create your first factory to get started. You can add rooms, users, and machines inside your factory to monitor their telemetry data.",
    icon: PiFactoryLight,
    background: "bg-pink-500",
    video: "create-factory",
  },
  {
    title: "How to add Users",
    description: "Video Tutorial: Invite users to collaborate.",
    icon: IoPeopleOutline,
    background: "bg-yellow-500",
    video: "add-user",
  },
  {
    title: "How to create, edit and delete Rooms",
    description: "Video Tutorial: Organize your factory by creating rooms.",
    icon: MdOutlineMeetingRoom,
    background: "bg-green-500",
    video: "manage-rooms",
  },
  {
    title: "How to add a Machine",
    description:
      "Video Tutorial: Add machines to rooms to start monitoring telemetry data.",
    icon: MdOutlineHeatPump,
    background: "bg-blue-500",
    video: "add-machine",
  },
  // {
  //   title: "Remote Control",
  //   description: "Remote control your machines using the Navigator Tool.",
  //   icon: MdOutlineCast,
  //   background: "bg-indigo-500",
  //   video: "manage-rooms",
  // },
  {
    title: "How to use upload wash programs in your Foamatic machine",
    description:
      "Video Tutorial: Upload and download wash programs to and from your Foamatic machines.",
    icon: BiTransferAlt,
    background: "bg-purple-500",
    video: "manage-rooms",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Help() {
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);

  return (
    <div className="mx-auto max-w-7xl">
      <TutorialVideoDialog
        open={open}
        setOpen={setOpen}
        video={selectedVideo}
        selectedTutorial={selectedTutorial}
      />
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Welcome to the Help Center
      </h2>
      <p className="mt-1 text-sm text-gray-500">
        Watch our Video Tutorials to get started using the platform together
        with your machines.
      </p>
      <ul
        role="list"
        className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
      >
        {items.map((item, itemIdx) => (
          <li
            key={itemIdx}
            className="flow-root"
            onClick={() => {
              setSelectedVideo(item.video);
              setSelectedTutorial(item);
              setOpen(true);
            }}
          >
            <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-blue-500 hover:bg-gray-50">
              <div
                className={classNames(
                  item.background,
                  "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg",
                )}
              >
                <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  <a href="#" className="focus:outline-none">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <span>{item.title}</span>
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {/* <div className="mt-4 flex">
        <a
          href="#"
          className="text-sm font-medium text-blue-600 hover:text-blue-500"
        >
          Or start from an empty project
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </div> */}
    </div>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import MachinesTable from "../Components/TableMachines/MachinesTable";
import { useRoomsByFactoryIdQuery } from "../../../hooks/factory/useRoomsByFactoryIdQuery";
import { useMachinesByFactoryIdQuery } from "hooks/machine/useMachinesByFactoryIdQuery";
import { useNotificationMessageByFactory } from "../../../hooks/machine";

export default function ViewFactoryMachines(props) {
  let { factoryId } = useParams();
  factoryId = parseInt(factoryId);

  const { data, isLoading } = useMachinesByFactoryIdQuery(factoryId);
  const { data: roomsData } = useRoomsByFactoryIdQuery(factoryId);
  const { data: notificationData } = useNotificationMessageByFactory(factoryId);
  console.log("notificationData", notificationData)
  return (
    <MachinesTable
      data={data?.data.machines}
      isLoading={isLoading}
      roomsData={roomsData?.data?.rooms}
      notificationData={notificationData?.data?.notificationMessages}
    />
  );
}

import { useQuery } from "@tanstack/react-query";
import {
  getSessionDataByMachineId,
  getOwnSessionDataByMachineId,
} from "api/machine.service";
import { useSelector } from "react-redux";

export const useMachineSessionDataQuery = (MachineId) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["machine", MachineId, "last-session-data"],
    queryFn: () =>
      isAdmin
        ? getSessionDataByMachineId(MachineId)
        : getOwnSessionDataByMachineId(MachineId),
    // enabled: !!serialNumber,
  });
};

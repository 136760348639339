import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  BoosterType,
  ParseBoosterType,
} from "../../Models/ParsingOfBoosterType";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { InfoElement as InfoElementView } from "./InfoElement";

type InfoElement = { name: string; value: string | number };
export default function InfoBox(): JSX.Element {
  return (
    <>
      <div className="overflow-visible bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Machine Information
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Serial number
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            test
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Type</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Mac address</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Manufacture date
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {/* <Grid item container xs={11} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" minHeight={"20vh"}>
        <InfoElements></InfoElements>
      </Grid> */}
    </>
  );
}

function CreateInfoElements(data: Record<string, string>): InfoElement[] {
  return Object.keys(data)
    .filter((key) => {
      return data[key] !== "NA";
    })
    .map((key) => {
      return { name: key, value: data[key] as string };
    });
}

function GetTotalTripCounter(
  TotalTripCounters: TotalTripCountersData,
): TotalTripCounter {
  const { "Install Time": omit1, "Last Reset Trip Time": omit2 } =
    TotalTripCounters;

  return { "Install Time": omit1, "Last Reset Trip Time": omit2 };
}
function InfoElements(): JSX.Element {
  const Server = GetServer();
  const [infoElements, SetinfoElements] = React.useState<InfoElement[]>([]);
  const [totalTripCounters, setTotalTripCounters] =
    React.useState<TotalTripCounter | null>(null);

  const [BoosterType, SetBoosterType] = React.useState<BoosterType | null>(
    null,
  );
  const server = GetServer();
  useEffect(() => {
    if (infoElements.length !== 0) {
      return;
    }

    server.SendCommand("GetSwVersionsJSON", null, (data, err) => {
      if (!err) {
        let infoElements = CreateInfoElements(data!);
        SetinfoElements(infoElements);
      }
    });
  }, [infoElements, server]);
  useEffect(() => {
    if (totalTripCounters !== null) {
      return;
    }
    server.SendCommand("GetTotalCountersJSON", null, (data, err) => {
      if (!err) {
        let totalTripCounters = data as any as TotalTripCountersData;
        setTotalTripCounters(GetTotalTripCounter(totalTripCounters));
      }
    });
  }, [totalTripCounters, server]);

  useEffect(() => {
    if (BoosterType !== null) {
      return;
    }
    server.GetEepromSettings((mabye_data, err) => {
      if (!err) {
        const data = mabye_data![1];
        SetBoosterType(ParseBoosterType(data));
      }
    });
  }, [BoosterType, server]);
  if (infoElements.length === 0 || !totalTripCounters) {
    return (
      <Grid
        item
        container
        xs={12}
        justifyContent={"center"}
        alignContent={"center"}
        bgcolor={"white"}
        borderRadius={"0.5vmax"}
        sx={{ overflow: "hidden" }}
        height={"max(110px, 22.3vhvh)"}
      >
        <CircularProgress></CircularProgress>
      </Grid>
    );
  }
  if (BoosterType) {
    return (
      <Paper
        sx={{
          width: "100%",
          height: "max(140px, 22.3vh)",
          "& .MuiTableCell-head": { lineHeight: "0.5vh" },
        }}
      >
        <TableContainer
          sx={{ height: "max(140px, 22.3vh)", overflowX: "hidden" }}
          className="scroll"
        >
          <Table stickyHeader>
            <TableHead sx={{ lineHeight: "15px" }}>
              <TableRow>
                <TableCell colSpan={2}>
                  <Grid container justifyContent={"center"}>
                    {" "}
                    <Typography
                      textAlign={"center"}
                      color={"black"}
                      variant="button"
                      fontSize={"0.7vmax"}
                    >
                      Machine Informaiton
                    </Typography>{" "}
                    <Grid item></Grid>{" "}
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <InfoElementView
                  name="Serial Number"
                  value={Server.getmahineId()}
                ></InfoElementView>
              </TableRow>
              <TableRow>
                {" "}
                <InfoElementView
                  name="Booster Type"
                  value={BoosterType.boosterType}
                ></InfoElementView>
              </TableRow>
              <TableRow>
                {" "}
                <InfoElementView
                  name="Machine Code"
                  value={BoosterType.boosterCode}
                ></InfoElementView>
              </TableRow>
              {infoElements.map((infoElement, idx) => {
                if (idx === infoElements.length - 1) {
                  return (
                    <TableRow sx={{ "& td": {} }}>
                      <InfoElementView
                        name={infoElement.name}
                        value={infoElement.value.toString()}
                      ></InfoElementView>{" "}
                    </TableRow>
                  );
                }
                return (
                  <TableRow>
                    <InfoElementView
                      name={infoElement.name}
                      value={infoElement.value.toString()}
                    ></InfoElementView>{" "}
                  </TableRow>
                );
              })}

              <TableRow sx={{ height: "0.5vmax" }}>
                <InfoElementView
                  name="Install Time"
                  value={totalTripCounters["Install Time"]}
                ></InfoElementView>
              </TableRow>
              <TableRow sx={{ "& td": { border: 0 } }}>
                {" "}
                <InfoElementView
                  name="Last Reset Trip Time"
                  value={totalTripCounters["Last Reset Trip Time"]}
                ></InfoElementView>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <>
      <InfoElementView
        name="DeviceId"
        value={Server.getmahineId()}
      ></InfoElementView>
      {infoElements.map((infoElement) => {
        return (
          <InfoElementView
            name={infoElement.name}
            value={infoElement.value.toString()}
          ></InfoElementView>
        );
      })}
    </>
  );
}

type TotalTripCountersData = {
  "Install Time": string;
  "Last Reset Trip Time": string;
};

type TotalTripCounter = {
  "Install Time": string;
  "Last Reset Trip Time": string;
};

import { useQuery } from "@tanstack/react-query";
import {
  getNotificationMessagesByFactoryId,
  getOwnNotificationMessagesByFactoryId,
} from "api/machine.service";
import { useSelector } from "react-redux";

export const useNotificationMessageByFactory = (FactoryId) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["factory", FactoryId, "notification-messages"],
    queryFn: () =>
      isAdmin
        ? getNotificationMessagesByFactoryId(FactoryId)
        : getOwnNotificationMessagesByFactoryId(FactoryId),
    // enabled: !!serialNumber,
  });
};

import { useNavigate } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FactoryRow({ factory, factoryIdx }) {
  const navigate = useNavigate();
  return (
    <tr
      className={classNames(
        factoryIdx === 0 ? "border-gray-300" : "border-gray-200",
        "cursor-pointer border-t hover:bg-gray-50",
      )}
      onClick={() => {
        console.log("clicked");
        navigate("/commander/" + factory.id + "/view/machines");
      }}
    >
      <td className="flex items-center space-x-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="hs-tooltip ">
          <MdErrorOutline
            size={22}
            className={`hs-tooltip-toggle ${factory?.hasError ? "text-red-500" : "text-gray-300"} `}
          />
          <span
            className="hs-tooltip-content invisible absolute z-10 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-neutral-700"
            role="tooltip"
          >
            {factory?.hasError ? "There are machines with errors" : "There are no machines with errors"}
          </span>
        </div>
        <div className="hs-tooltip ">
          <IoWarningOutline
            size={22}
            className={`hs-tooltip-toggle ${factory?.hasWarning ? "text-orange-400" : "text-gray-300"} `}
          />
          <span
            className="hs-tooltip-content invisible absolute z-10 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-neutral-700"
            role="tooltip"
          >
            {factory?.hasWarning ? "There are machines with warnings" : "There are no machines with warnings"}
          </span>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
        {factory.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {factory.address}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {factory.city}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {factory.country}{" "}
      </td>
    </tr>
  );
}

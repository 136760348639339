import React, { useEffect, useRef, useState } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useTelemetryDataQuery } from "hooks/machine";
import {
  initiateSocket,
  getSocket,
  joinRoom,
} from "shared/services/socket.service";
import LineChartNew from "pages/Dashboard/Chart/LineChartNew";
import LineChartInverter from "pages/Dashboard/Chart/LineChartInverter";
import MiniChart from "../../Dashboard/Chart/MiniChart";
import MiniChartRegulating from "../../Dashboard/Chart/MiniChartRegulating";
import BF32_LEFT from "shared/utils/images/Machines/BF32_LEFT.png";
import BF32_RIGHT from "shared/utils/images/Machines/BF32_RIGHT.png";
import MiniChartSinglePump from "../../Dashboard/Chart/MiniChartSinglePump";
var format = require("date-format");

export default function ChartsWrapperV2(props) {
  const { machineData } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataOrFound, setNoDataOrFound] = useState(false);

  const {
    data: telemetryData,
    isLoading: machineLoading,
    error: machineError,
  } = useTelemetryDataQuery(machineData?.serialNumber);

  useEffect(() => {
    if (telemetryData) {
      const data = telemetryData?.data?.device;

      setData(data ?? []);

      setLoading(false);
    }
  }, [telemetryData]);

  useEffect(() => {
    let numberOfPumps = 0;
    let socket = getSocket();
    // console.log("sockeet", socket);
    if (socket) joinRoom(machineData?.serialNumber);

    socket.on("connect_error", (err) => {
      console.log("Connection Error socket", err);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected socket");
      setTimeout(() => {
        socket.connect();
      }, 2000);
    });

    socket.on("connect", function () {
      console.log("Connected socket");
    });

    socket.on("reconnect", () => {
      console.log("Trying to reconnect socket.io");
    });

    socket.on("telemetry-" + machineData?.serialNumber, (data) => {
      const messageData = JSON.parse(data);
      let device = messageData.IotData;
      // console.log("device", device);
      var flowSwitch = 0;
      if (device.FlowSw > 1000) {
        flowSwitch = 10;
      }
      if (device.Temperature_Room > 1000) {
        device.Temperature_Room = 0;
      }
      if (device.Temperature_igbt > 1000) {
        device.Temperature_igbt = 0;
      }

      device = {
        ...device,
        EventEnqueuedUtcTime: messageData.MessageDate,
        Inverter_loadPowP: device.Inverter_loadPowP / 1000,
        FlowSw: flowSwitch,
      };

      if (data.length >= 270)
        setData((prevState) => [...prevState.slice(1), device]);
      //removing old telemetry data once a new one is added
      else setData((prevState) => [...prevState, device]); //removing old telemetry data once a new one is added
    });
  }, [machineData]);

  // console.log("data", data);
  return (
    <div>
      <div className=" grid grid-cols-1 justify-between gap-4 md:grid-cols-2 2xl:grid-cols-4">
        <PumpsLoop
          telemetryData={data}
          telemetryType={"PressureIN"}
          loading={loading}
          noDataOrFound={noDataOrFound}
        />
        <PumpsLoop
          telemetryData={data}
          telemetryType={"PressureOUT"}
          loading={loading}
          noDataOrFound={noDataOrFound}
        />
        <PumpsLoop
          telemetryData={data}
          telemetryType={"Temperature_Water"}
          loading={loading}
          noDataOrFound={noDataOrFound}
        />
        {/* <PumpsLoop
                  noOfPumps={noOfPumps}
                  telemetryData={data}
                  telemetryType={"Temperature_igbt"}
                  loading={loading}
                  noDataOrFound={noDataOrFound}
                /> */}
        {/* <PumpsLoop
                  noOfPumps={noOfPumps}
                  telemetryData={data}
                  telemetryType={"InverterFreqReadout"}
                  loading={loading}
                  noDataOrFound={noDataOrFound}
                /> */}
        <PumpsLoop
          telemetryData={data}
          telemetryType={"Inverter_loadPowP"}
          loading={loading}
          noDataOrFound={noDataOrFound}
        />
      </div>
    </div>
  );
}

const PumpsLoop = ({
  telemetryData: data,
  telemetryType,
  loading,
  noDataOrFound,
}) => {
  // Generate an array for looping

  return (
    <div className="overflow-hidden rounded-2xl bg-white  shadow-sm ring-1 ring-gray-900/5">
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="min-h-80 min-w-80  px-4 py-6  ">
            <div className="flex flex-col   ">
              <div className="m-2   rounded-lg border-2 border-gray-200  bg-gray-50">
                <dl className="divide-y divide-gray-100">
                  <div className="min-h-20 min-w-20 px-4 py-2 sm:px-4">
                    <MiniChartSinglePump
                      telemetryData={data}
                      pumpNo={0} // Pass pump number dynamically
                      telemetryType={telemetryType}
                      loading={loading}
                      noDataOrFound={noDataOrFound}
                    />

              <p className="mt-2 max-w-2xl text-sm leading-6 text-gray-500">
                Telemetry data from the last 10 minutes
              </p>
                  </div>
                </dl>
              </div>

              {/* <p className="ml-2 max-w-2xl text-sm leading-6 text-gray-500">
                Telemetry data from the last 10 minutes
              </p> */}
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};

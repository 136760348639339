import React, { useEffect, useState } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
var format = require("date-format");

export default function MiniChartSinglePump({
  telemetryData,
  telemetryType,
  loading,
  noDataOrFound,
  type,
}) {
  const [unitSystem, setUnitSystem] = useState("metric");

  useEffect(() => {
    const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";
    setUnitSystem(storedUnitSystem);
  }, []);
  const COLORS = [
    "#1abc9c",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#f1c40f",
    "#e74c3c",
    "#7f8c8d",
    "#107C10",
    "#E81123",
    "#FF8C00",
    "#0099BC",
  ];
  const tickFormatter = (tick) => format("hh:mm:ss", new Date(tick));
  const labelFormatter = (date) =>
    format("dd/MM/yyyy hh:mm:ss", new Date(date));

  const telemetryDataNew = convertTelemetryData(telemetryData);

  //conly show the data from telemetryData that has the InverterSlaveIdPacket equal to pumpNo

  const getCardTitle = () => {
    switch (telemetryType) {
      case "PressureIN":
        return "Inlet Pressure";
      case "PressureOUT":
        return "Outlet Pressure";
      case "Temperature_Water":
        return "Water temperature";
      case "Temperature_igbt":
        return "Power Module";
      case "InverterFreqReadout":
        return "Inverter Frequency";
      case "Inverter_loadPowP":
        return "Power";
      default:
        return null;
    }
  };

  const getCardUnit = () => {
    const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";

    switch (telemetryType) {
      case "PressureIN":
      case "PressureOUT":
        return storedUnitSystem === "imperial" ? " psi" : " Bar";

      case "Temperature_Water":
      case "Temperature_igbt":
        return storedUnitSystem === "imperial" ? " °F" : " °C";

      case "InverterFreqReadout":
        return " Hz";

      case "Inverter_loadPowP":
        return " kW";

      default:
        return null;
    }
  };

  const getConvertedValue = (value, type) => {
    if (unitSystem === "imperial") {
      switch (type) {
        case "PressureIN":
          return (value * 14.5038).toFixed(1) + " psi";
        case "PressureOUT":
          return (value * 14.5038).toFixed(0) + " psi";
        case "Temperature_Water":
        case "Temperature_igbt":
          return ((value * 9) / 5 + 32).toFixed(0) + " °F";
        case "Inverter_loadPowP":
          return (value / 1000).toFixed(1) + " kW";
        default:
          return value.toFixed(2);
      }
    } else {
      switch (type) {
        case "PressureIN":
          return value.toFixed(1) + " Bar";
        case "PressureOUT":
          return value.toFixed(0) + " Bar";
        case "Temperature_Water":
        case "Temperature_igbt":
          return value.toFixed(0) + " °C";
        case "Inverter_loadPowP":
          return value.toFixed(1) / 1000 + " kW";
        default:
          return value.toFixed(1);
      }
    }
  };
  const getLineColor = () => {
    switch (telemetryType) {
      case "PressureIN":
        return COLORS[0];
      case "PressureOUT":
        return COLORS[1];
      case "Temperature_Water":
        return COLORS[2];
      case "Temperature_igbt":
        return COLORS[3];
      case "InverterFreqReadout":
        return COLORS[4];
      case "Inverter_loadPowP":
        return COLORS[5];
      default:
        return COLORS[6];
    }
  };

  const getYAxisDomain = () => {
    const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";

    switch (telemetryType) {
      case "PressureIN":
      case "PressureOUT":
        return storedUnitSystem === "imperial" ? [0, 600] : [0, 40]; // Bar → PSI

      case "Temperature_Water":
      case "Temperature_igbt":
        return storedUnitSystem === "imperial" ? [30, 250] : [0, 120]; // °C → °F

      case "InverterFreqReadout":
        return [0, 100]; // Fixed range for frequency

      case "Inverter_loadPowP":
        return [0, 100]; // Fixed range for power (kW)

      default:
        return [0, 100]; // Default fallback
    }
  };

  // console.log("telemetryData", telemetryData);
  return (
    <div>
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        {getCardTitle()}
      </h3>
      <h3 className="text-2xl font-bold leading-9 text-gray-900">
        {telemetryData.length
          ? getConvertedValue(
              telemetryData[telemetryData.length - 1][telemetryType],
              telemetryType,
            )
          : "N/A"}
      </h3>
      {loading ? (
        <div className="h-80 w-auto animate-pulse rounded bg-slate-200"></div>
      ) : noDataOrFound ? (
        <div>
          <div className="flex flex-row items-center justify-center">
            <div className="text-base text-gray-700">
              We could not receive data from the machines <br />
              The machine might be turned off or the internet connection is
              lost.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                label={<CustomLabel />}
                data={telemetryDataNew}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                syncId={"telemetry-page"}
              >
                <CartesianGrid
                  strokeDasharray="2 2"
                  horizontalCoordinatesGenerator={(props) => [
                    50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550,
                  ]}
                  verticalCoordinatesGenerator={(props) =>[
                    50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550
                  ]}
                />
                <XAxis
                  dataKey="EventEnqueuedUtcTime"
                  tickFormatter={tickFormatter}
                  angle={30}
                  dx={20}
                  tickMargin={15}
                  height={50}
                  type="category"
                  hide={true}
                ></XAxis>
                {/* <YAxis yAxisId="left" unit=' Bar' domain={[0, 40]} />  label={{ value: "Bar", position: 'insideLeft' }} domain={['dataMin', 'dataMax']} label={{ value: this.state.widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}

                {/* label={{ value: "°C", position: 'insideRight' }}*/}
                <YAxis
                  yAxisId="left"
                  domain={getYAxisDomain()}
                  unit={getCardUnit()}
                  hide={true}
                />
                {/*label={{ value: "Bar", position: 'insideLeft' }}  domain={['dataMin', 'dataMax']} label={{ value: widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}
                <Tooltip
                  // position={{ x: -20 }}

                  // allowEscapeViewBox={{ x: false, y: true }}
                  labelFormatter={(t) => labelFormatter(t)}
                  isAnimationActive={false}
                />
                <Line
                  unit={getCardUnit()}
                  strokeWidth={2}
                  legendType="plainline"
                  isAnimationActive={false}
                  type="monotone"
                  dataKey={telemetryType}
                  stroke={getLineColor()}
                  name={getCardTitle()}
                  dot={false}
                  yAxisId="left"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div id="html-dist"></div>
        </div>
      )}
    </div>
  );
}
function CustomLabel({ x, y, stroke, value, width }) {
  if (value) {
    // No label if there is a value. Let the cell handle it.
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      // Move slightly above axis
      dy={-10}
      // Center text
      dx={width / 2}
      fill={stroke}
      fontSize={15}
      textAnchor="middle"
    >
      N/A
    </text>
  );
}

const convertTelemetryData = (data) => {
  const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";

  return data.map((item) => {
    const convertedItem = { ...item };

    if (storedUnitSystem === "imperial") {
      // Convert relevant fields
      if (item.PressureIN !== undefined) {
        convertedItem.PressureIN = (item.PressureIN * 14.5038).toFixed(2); // Bar to PSI
      }
      if (item.PressureOUT !== undefined) {
        convertedItem.PressureOUT = (item.PressureOUT * 14.5038).toFixed(2); // Bar to PSI
      }
      if (item.Temperature_Water !== undefined) {
        convertedItem.Temperature_Water = (
          (item.Temperature_Water * 9) / 5 +
          32
        ).toFixed(2); // °C to °F
      }
      if (item.Temperature_igbt !== undefined) {
        convertedItem.Temperature_igbt = (
          (item.Temperature_igbt * 9) / 5 +
          32
        ).toFixed(2); // °C to °F
      }
    }

    return convertedItem;
  });
};
